/**
 * 新下单打印和支付成功方法
 * */
import HardwareService from "../../common/domains/hardware-domain/hardwareService";
import {
  // orderCateringPrint,
  orderQuickCombinationPrint,
  orderQuickTicketPrint,
  orderQuickretailprint
} from "../../api/search";
import { orderPrint } from "../../api/order";

export default {
  methods: {
    // 支付成功
    payOk(orderId, noPrint = false) {
      // 语音播报
      HardwareService.speak("支付成功");
      // 如果挂账，则不需要打印功能
      if (!noPrint) {
        // 餐饮下的单，调用云打印机 餐饮单品-----1435059066526212100  // 餐饮套餐-----1435059066526212101
        // let currentTopMenuKey = this.$store.state.common.currentTopMenuKey;
        // if (currentTopMenuKey === "cy") {
        //   orderCateringPrint({ orderId })
        //     .then(() => {})
        //     .catch(() => {});
        // } else {
        //   // 非餐饮的订单，调本地打印机
        //   this.printProof(orderId);
        // }
        this.printProof(orderId);
      }
    },
    // 打印凭证
    printProof(orderId) {
      let Hardware = localStorage.getItem("Hardware");
      let setting;
      if (Hardware) {
        setting = JSON.parse(Hardware);
      }
      // 如果存在打印接口
      if (setting && setting.printTicket) {
        // 选中的业态
        let currentTopMenuKey = this.$store.state.common.currentTopMenuKey;
        // 票务、组合、游乐调用相同的打印硬件方法
        if (
          currentTopMenuKey === "appNewHome" ||
          currentTopMenuKey === "appCombinationNew"
        ) {
          let api;
          // 票务
          if (currentTopMenuKey === "appNewHome") {
            api = orderQuickTicketPrint;
          } else if (currentTopMenuKey === "appCombinationNew") {
            // 组合产品
            api = orderQuickCombinationPrint;
          }
          api({
            orderId: orderId
          })
            .then(res => {
              if (res) {
                let flag = HardwareService.printTicket(JSON.stringify(res));
                //如果成功调用打印门票，就掉接口通知后端已经取票
                if (flag) {
                  orderPrint({
                    channel: 6, //6是窗口端
                    orderId: orderId
                  })
                    .then(() => {})
                    .catch(() => {});
                }
              }
            })
            .catch(() => {});
        }
        // 零售打印小票
        else if (currentTopMenuKey === "appRetailNew") {
          this.printSmallTicket(orderId);
        }
      }
    },
    // 打印小票
    printSmallTicket(orderId) {
      let api;
      let currentTopMenuKey = this.$store.state.common.currentTopMenuKey;
      if (currentTopMenuKey === "appRetailNew") {
        api = orderQuickretailprint;
      }
      api({
        orderId
      })
        .then(res => {
          if (res) {
            let flag = HardwareService.printRetail(JSON.stringify(res));
            //如果成功调用打印门票，就掉接口通知后端已经取票
            if (flag) {
              orderPrint({
                channel: 6, //6是窗口端
                orderId: orderId
              })
                .then(() => {})
                .catch(() => {});
            }
          }
        })
        .catch(() => {});
    }
  }
};
