<template>
  <div class="wrapper flex-col flex-start" v-if="visible">
    <panel-head title="直接收款" @close="handleClose"></panel-head>

    <div class="main">
      <div class="flex-center">
        <a-radio-group
          v-model="payType"
          button-style="solid"
          @change="changePayType"
          size="large"
        >
          <a-radio-button :value="2"> 无产品收银 </a-radio-button>
          <a-radio-button :value="9"> 产品补差价 </a-radio-button>
        </a-radio-group>
      </div>
      <div class="flex-center ph-2x" v-show="payType == 2">
        <a-radio-group v-model="merchantType" @change="merchantChange">
          <a-radio
            class="mt-2x"
            v-for="item in merchantList"
            :key="item.id"
            :value="item.id"
            >{{ item.merchantName }}</a-radio
          >
        </a-radio-group>
      </div>
      <a-divider />
      <div
        v-if="payType == 9"
        class="flex-center flex-col flex-wrap align-center"
      >
        <div class="flex-start align-center pb-1x" style="width: 900px">
          <span style="width: 60px">产品号</span>
          <!--<a-input-->
          <!--  v-inputfocus-->
          <!--  placeholder="请输入票号/手机号/身份证/卡腕带编号"-->
          <!--  allow-clear-->
          <!--  v-model.trim="ticketNo"-->
          <!--  ref="ticketRef"-->
          <!--  @pressEnter="queryTicket"-->
          <!--&gt;</a-input>-->
          <IcCardInput
            ref="ticketRef"
            v-inputfocus
            v-model="ticketNo"
            :allow-clear="false"
            @pressEnter="queryTicket"
            placeholder="请输入票号/手机号/身份证/卡腕带编号"
          ></IcCardInput>
          <a-button type="primary" @click="queryTicket" class="ml-2x"
            >查询</a-button
          >
        </div>
        <a-table
          :columns="ticketColumns"
          :data-source="ticketList"
          rowKey="auxiliaryId"
          :pagination="false"
          class="prefix-input"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            type: 'radio'
          }"
          :scroll="{ y: 430 }"
          :customRow="loadCustomRow"
        >
          <!--<template slot="action" slot-scope="row">-->
          <!--  <a-button type="link" @click="goRefundDetail(row)">补差价</a-button>-->
          <!--</template>-->
        </a-table>
      </div>
      <a-form autocomplete="off" class="text-c">
        <a-input
          v-inputfocus
          class="prefix-input"
          :style="{ width: '532px' }"
          placeholder="请输入收款事项"
          allow-clear
          v-model.trim="remark"
        >
          <div slot="prefix" class="font-xl primary">备注收款事项</div>
        </a-input>

        <keyboard-pro type="2" v-model="numbers" @ok="currentPay">
          <template v-slot:input="{ confirm }">
            <a-input
              v-inputfocus
              @pressEnter="confirm()"
              size="large"
              class="prefix-input"
              :style="{ width: '532px' }"
              allow-clear
              v-model="numbers"
            >
              <div slot="prefix" class="font1 primary">本次收款金额</div>
            </a-input>
          </template>
        </keyboard-pro>
      </a-form>
    </div>

    <!--支付方式-->
    <pay-way
      :cart-id="cartId"
      is-modal
      ref="PayWay"
      @cancel="clearCart"
      @success="success"
    ></pay-way>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import KeyboardPro from "@/components/common/KeyboardPro";
import { skuDifference, skuNoProduct } from "../../../api/product";
import { orderTicketPremium } from "@/api/search";
import { commonNoProduct } from "../../../api/cart";
import PayWay from "./PayWay";
import { fix2Reg } from "../../../utils/global";
import { getmerchantListType } from "../../../api/merchant";
import IcCardInput from "@/components/hardware/IcCardinput";
export default {
  name: "PayOnly",

  components: {
    PanelHead,
    KeyboardPro,
    PayWay,
    IcCardInput
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // 默认选择 票务
      merchantType: "",
      // 获取商户列表
      merchantList: [],
      form: this.$form.createForm(this, { name: "myform" }),
      remark: "",
      numbers: "",
      productId2: "", // 无产品收银产品id
      productId9: "", // 补差价产品id
      payType: 2, //2无票支付 9门票补差价
      ticketColumns: [
        {
          title: "产品号",
          dataIndex: "auxiliaryId",
          key: "auxiliaryId",
          width: 130
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName",
          width: 170,
          ellipsis: true
        },
        {
          title: "产品状态",
          key: "statusText",
          dataIndex: "statusText",
          width: 90
        },
        {
          title: "销售渠道",
          dataIndex: "buyChannelsText",
          key: "buyChannelsText",
          width: 120
        }
      ],
      ticketList: [],
      ticketNo: "",
      selectedRowKeys: [], // 选中项
      cartId: 0 // 购物车id
    };
  },
  computed: {
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    }
  },
  created() {
    this.getProduct9();
    this.getmerchantList();
  },
  methods: {
    loadCustomRow(record) {
      return {
        on: {
          click: () => {
            //监听的是单选框的点击事件
            this.selectedRowKeys = [record.auxiliaryId];
            this.remark = "补差价";
          },
          change: () => {
            //监听的是行的点击事件
            this.selectedRowKeys = [record.auxiliaryId];
            this.remark = "补差价";
          }
        }
      };
    },
    success() {
      this.numbers = "";
      this.remark = "";
      this.$emit("success", true);
    },
    // 更改参数
    changeData(val) {
      this.numbers = val.money;
      this.remark = val.remark;
    },
    merchantChange(e) {
      //获取无收银产品
      this.getProduct2(e.target.value);
    },
    // 获取商户列表
    getmerchantList() {
      const val = {
        appHome: 6,
        appNewHome: 6,
        appPlayNew: 7,
        appCombinationNew: "",
        appRetailNew: 11,
        appTheaterNew: 8
      };
      // 最新业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      getmerchantListType({ merchantType: val[this.currentTopMenuKey] }).then(
        res => {
          this.merchantList = res;
          this.merchantType = res[0].id;
          // 获取商户列表 获取第一个默认的产品id
          this.getProduct2(this.merchantType);
        }
      );
    },
    // 获取无产品收银
    getProduct2(id) {
      if (!id) return;
      skuNoProduct({ merchantId: id })
        .then(res => {
          this.productId2 = res.id;
        })
        .catch(() => {});
    },
    // 获取无产品收银
    getProduct9() {
      skuDifference()
        .then(res => {
          this.productId9 = res.id;
        })
        .catch(() => {});
    },
    //切换是否补差价
    changePayType() {
      if (this.payType === 9) {
        this.$nextTick(() => {
          this.$refs.ticketRef.focus();
        });
      }
      this.selectedRowKeys = [];
      this.ticketList = [];
      this.numbers = "";
      this.remark = "";
    },
    queryTicket() {
      if (!this.ticketNo) {
        this.$message.warning("请输入票号/手机号/身份证/卡腕带编号");
        return;
      }
      this.selectedRowKeys = [];
      orderTicketPremium({ keyword: this.ticketNo }).then(res => {
        this.ticketList = res;
      });
    },
    currentPay() {
      if (this.remark == "") {
        this.$message.warning("请输入收款事项");
        return false;
      } else if (this.numbers == "") {
        this.$message.warning("请输入收款金额");
        return false;
      } else if (!fix2Reg.test(this.numbers)) {
        this.$message.warning("请输入正确的金额，最多保留两位小数");
        return false;
      } else if (this.payType == 9) {
        if (!this.ticketNo) {
          this.$message.warning("请输入票号");
          return;
        }
        if (!this.selectedRowKeys.length) {
          this.$message.warning("暂无选中产品");
          return;
        }
      }
      //加入购物车
      let data = {
        productId: this[`productId${this.payType}`],
        businessCategory: this.payType, //2是自定义收款
        remark: this.remark,
        changePrice: Math.round(this.numbers * 10000) / 100
      };
      if (this.payType === 9) data.ticketNo = this.selectedRowKeys[0];
      commonNoProduct(data)
        .then(res => {
          this.cartId = res.id;
          this.$nextTick(() => {
            this.$refs.PayWay.pay();
          });
        })
        .catch(() => {});
    },
    clearCart() {
      this.cartId = 0;
    },
    // 关闭
    handleClose() {
      this.selectedRowKeys = [];
      this.ticketList = [];
      this.numbers = "";
      this.remark = "";
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  padding-top: 24px;
  margin-top: 16px;
}
.prefix-input {
  width: 900px;
  margin-bottom: 16px;
}
</style>
