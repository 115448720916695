<!--支付方式-->
<!--
  @method: pay  直接付款 调用下单支付
  @event: {
    success: 支付成功事件
    cancel： 取消支付事件
  }
-->
<template>
  <div>
    <!--支付方式-->
    <div
      class="flex-start align-center"
      v-if="getSettings.IsShowSpeedOrder && !isModal"
    >
      <b class="flex-shrink">支付方式：</b>
      <a-radio-group v-model="payValue" @change="payChange">
        <a-radio value="payOnline">识别凭证</a-radio>
        <a-input
          v-inputfocus
          v-model="payCode"
          allow-clear
          placeholder="请输入"
          style="width: 246px; margin-right: 16px"
          @pressEnter="scanEnter"
          @focus="readIdCard"
          @blur="clearReadIdCard"
        >
          <a-icon slot="suffix" type="scan" style="color: #2384e5" />
        </a-input>
        <!--线下-->
        <a-radio
          class="pay-way-radio"
          v-for="item in payOffline"
          :value="item.payWayId"
          :key="item.payWayId"
          >{{ item.payWayName }}</a-radio
        >
        <!--挂账-->
        <a-radio class="pay-way-radio" value="payAfter" v-if="payAfter">
          挂账</a-radio
        >
      </a-radio-group>
    </div>
    <!--支付确认弹窗-->
    <div class="modal" v-show="showPayModal">
      <div class="center pay-modal">
        <p class="font-xxl primary text-c p-2x bd-b">请确认支付</p>
        <div class="pay-way-s">
          <div class="p-2x">
            <div class="flex-between">
              <p class="bold" v-if="settlementPrice > 0">
                付款金额：{{ settlementPrice | money }}
              </p>
              <p class="bold" v-if="refundPrice > 0">
                退款金额：{{ refundPrice | money }}
              </p>
              <p class="bold" v-if="settlementPrice > 0">
                实付金额：<span class="red">{{
                  (settlementPrice - refundPrice) | money
                }}</span>
              </p>
            </div>
            <p class="mt-2x">
              切换支付方式
            </p>
          </div>
          <!--添加 @change="payChange"，解决单单使用支付弹框时拿不到支付名称-->
          <a-radio-group class="ph-3x" v-model="payValue" @change="payChange">
            <template>
              <a-radio class="modal-radio" value="payOnline">
                <span class="mr-3x">识别凭证</span>
                <a-input
                  v-show="payValue === 'payOnline'"
                  v-model="payCode"
                  v-inputfocus
                  placeholder="请输入"
                  allow-clear
                  @pressEnter="scanEnter"
                  @focus="readIdCard"
                  @blur="clearReadIdCard"
                >
                  <a-icon slot="suffix" type="scan" style="color: #2384e5" />
                </a-input>
              </a-radio>
              <!--线下-->
              <a-radio
                class="modal-radio"
                v-for="item in payOffline"
                :value="item.payWayId"
                :key="item.payWayId"
                >{{ item.payWayName }}</a-radio
              >
              <!--挂账-->
              <a-radio class="modal-radio" value="payAfter" v-if="payAfter">
                <span class="mr-3x">挂账</span>
              </a-radio>
            </template>
          </a-radio-group>
        </div>

        <div class="flex-center p-2x bd-t">
          <a-button
            shape="round"
            style="width: 130px; margin-right: 24px"
            @click="clear"
            >取消</a-button
          >
          <a-button
            type="primary"
            style="width: 130px"
            shape="round"
            @click="surePay"
            >确认</a-button
          >
        </div>
      </div>
    </div>

    <!--会员支付确认弹窗-->
    <div class="modal" v-show="showMember">
      <div class="center p-3x">
        <h2 class="text-c">会员信息</h2>
        <p class="mt-2x">姓名：{{ member.name }}</p>
        <p class="mv-2x">手机号：{{ member.mobile }}</p>
        <div class="flex-start align-center">
          <p class="flex-shrink">支付密码：</p>
          <a-input
            v-inputfocus
            :max-length="6"
            type="password"
            placeholder="请输入支付密码"
            allow-clear
            v-model="memberPassword"
            @pressEnter="checkMemberPass"
          ></a-input>
        </div>
        <div class="flex-center mt-3x">
          <a-button
            shape="round"
            style="width: 130px; margin-right: 24px"
            @click="clear"
            >取消</a-button
          >
          <a-button
            type="primary"
            style="width: 130px"
            shape="round"
            @click="checkMemberPass"
            >确认</a-button
          >
        </div>
      </div>
    </div>

    <!--等待支付弹窗-->
    <div class="modal" v-show="showWait">
      <div class="center text-c primary">
        <div class="loading-box">
          <img
            src="../../../assets/images/shalou.png"
            alt=""
            class="loading-icon"
          />
          <p style="font-size: 28px" class="mt-3x">等待支付</p>
        </div>
      </div>
    </div>

    <!--会员资产弹窗-->
    <div class="modal member-property-modal" v-show="showMemberProperty">
      <div class="center">
        <h2 class="text-c ph-3x">会员资产</h2>

        <hr />

        <div class="flex-col flex-between ph-3x model-center-main">
          <div
            class="modal-content-list-item flex-between flex-col mt-2x"
            v-if="memberProperty.memberProductInformationVO.whetherUsable"
          >
            <div class="flex-between">
              <span>
                <span class="title">
                  我的产品：
                </span>
                <span
                  v-if="
                    !memberProperty.memberProductInformationVO.whetherUsable
                  "
                  class="highlight"
                  >（以下产品不可用）</span
                ></span
              >
              <a-button
                :disabled="
                  !memberProperty.memberProductInformationVO.whetherUsable
                "
                shape="round"
                size="small"
                plain
                @click="onClickWriteOffProduct"
                >产品核销</a-button
              >
            </div>

            {{ /* 不可用产品列表 */ }}
            <div
              v-if="!memberProperty.memberProductInformationVO.whetherUsable"
            >
              <span
                v-for="(item, index) in memberProperty
                  .memberProductInformationVO.memberProductDeficiencyVOList"
                :key="index"
                >{{ item.productName }} x{{ item.productNum }}
                {{
                  memberProperty.memberProductInformationVO
                    .memberProductDeficiencyVOList.length -
                    1 ==
                  index
                    ? ""
                    : "，"
                }}
              </span>
            </div>
            <div v-else>
              <a-checkbox-group
                v-model="productAuxiliaryIdList"
                class="checkbox-list mt-2x"
              >
                <a-checkbox
                  v-for="(item, index) in memberProperty
                    .memberProductInformationVO.memberAvailableProductVOList"
                  :key="index"
                  :value="item.auxiliaryId"
                  class="checkbox-list-item"
                  >{{ item.productName }}</a-checkbox
                >
              </a-checkbox-group>
            </div>
          </div>

          {{ /* 接口未实现，暂不显示 */ }}
          <div class="modal-content-list-item flex-between mv-2x">
            <span>
              <span class="title">
                兑换券支付
              </span>
              <span
                class="highlight"
                v-if="
                  !memberProperty.memberAssetCoinCertificateVO.whetherUsable
                "
                >（以下产品不可用）</span
              ></span
            >
            <a-button
              :disabled="
                !memberProperty.memberAssetCoinCertificateVO.whetherUsable
              "
              shape="round"
              size="small"
              plain
              @click="coinCertificatePay"
              >兑换支付</a-button
            >
          </div>

          <div class="flex-between flex-col mv-2x">
            <div class="flex-between">
              <span>
                <span class="title">
                  兑换币支付
                </span>
                <span
                  v-if="
                    !memberProperty.memberConvertibleCurrencyVO.whetherUsable
                  "
                  class="highlight"
                  >：（以下产品不可用）</span
                >
              </span>
              <a-button
                :disabled="
                  !memberProperty.memberConvertibleCurrencyVO.whetherUsable
                "
                shape="round"
                size="small"
                plain
                @click="onClickCurrencyPay"
                >兑换支付</a-button
              >
            </div>
            <div>
              <span
                v-for="(item, index) in memberProperty
                  .memberConvertibleCurrencyVO.memberProductDeficiencyVOList"
                :key="index"
              >
                {{ item.productName }}（差{{ item.lackMoneyNum / 100 }}元）
              </span>
            </div>
          </div>
        </div>

        <hr />

        <div class="flex-center mt-3x ph-3x">
          <a-button
            shape="round"
            style="width: 130px; margin-right: 24px"
            @click="onClickCancelMemberProperty"
            >取消</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { merchantuthPayFormWayInfo } from "../../../api/pay";
import { commonMember, cartCommonDetail } from "../../../api/cart";
import { mapGetters } from "vuex";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
import {
  createQuickOrder,
  payDeductionOrder,
  payOfflineOrder,
  payOncreditOrder,
  tradePaymentInfoMicropayV2,
  tradePaymentInfoStatus,
  productWriteOffStrictBatch,
  payCouponOrder
} from "../../../api/order";
import {
  orderOneMemberInfo,
  memberPaymentInformation,
  checkMemberPasswordInfo
} from "../../../api/member";
import mixins from "../mixins";

export default {
  name: "PayWay",

  props: {
    cartId: {
      type: Number,
      default: 0
    },
    isModal: {
      type: Boolean,
      default: false
    },
    // 是否有优惠
    preferential: {
      type: Boolean,
      default: false
    },
    // 展示余额支付
    showMemberBalance: {
      type: Boolean,
      default: true
    },
    // 展示挂账支付
    showPayAfter: {
      type: Boolean,
      default: true
    },
    // 购物信息校验出游人信息方法
    validate: {
      type: Function,
      default() {
        return function() {
          return true;
        };
      }
    }
  },

  mixins: [mixins],

  data() {
    return {
      payValue: "payOnline",
      showPayModal: false, // 支付确认弹窗
      showMember: false, // 展示会员信息
      showWait: false, //
      loading: false, // 加载中、支付中
      payWayName: "", // 支付名称
      payWayMemberName: "", // 会员支付名称
      // 是否支持线上支付
      payOnline: false, // 是否支持线上支付
      payMember: false, // 是否支持会员支付
      payOffline: [], // 线下支付
      payAfter: false, // 挂账
      payCode: "", // 凭证码
      memberPassword: "", // 会员支付密码
      member: {}, // 会员信息
      showMemberProperty: false, //是否显示会员资产弹窗
      memberProperty: {
        memberAssetCoinCertificateVO: {
          consumeRecordList: [],
          memberProductDeficiencyVOList: [],
          whetherUsable: false
        }, // 会员资产-优惠券
        memberConvertibleCurrencyVO: {
          memberProductDeficiencyVOList: [],
          whetherUsable: false
        }, // 会员资产-兑换币
        memberProductInformationVO: {
          memberAvailableProductVOList: [],
          memberProductDeficiencyVOList: [],
          productAvailList: [],
          whetherUsable: false
        } // 会员资产-产品
      }, //会员资产信息
      productAuxiliaryIdList: [],
      orderId: "", // 订单id
      isLoginMember: false, // 是否登录会员
      ownerFormatTypes: [], // 订单包含的业态类型，打印用
      cartInfo: {}, //购物车详情
      buyNum: 0, // 购买数量
      settlementPrice: 0, // 订单金额
      refundPrice: 0 // 退款金额
    };
  },

  computed: {
    ...mapGetters({
      // 页面布局
      getSettings: "setting/getSettings"
    }),
    ...mapGetters("common", ["memberInfo"])
  },

  // inject: ["validate"],

  created() {
    this.loadPay();
  },

  beforeDestroy() {
    this.clearReadIdCard();
  },

  methods: {
    // 获取支付方式
    loadPay() {
      merchantuthPayFormWayInfo()
        .then(res => {
          // payFormCategory: 支付形式  1线上支付、2线下支付， 3挂账， 4抵扣
          res.forEach(item => {
            if (item.payFormCategory === 1) {
              this.payOnline = true;
            } else if (item.payFormCategory === 2) {
              this.payOffline = item.authPayWayVOS;
            } else if (item.payFormCategory === 3 && this.showPayAfter) {
              this.payAfter = true;
            } else if (item.payFormCategory === 4 && this.showMemberBalance) {
              const temp = item.authPayWayVOS.find(
                temp => temp.payWayCode === "MemberBalance"
              );
              if (temp) {
                this.payMember = !!temp;
                this.payWayMemberName = temp.payWayName;
              }
            }
          });
        })
        .catch(() => {});
    },
    // 检查会员是否登录
    async checkMem() {
      // 扫会员码支付
      if (/(^1[3456789]\d{9}$)|(^09\d{8})$/.test(this.payCode)) {
        // 扫码的会员和登录的会员不一致弹提示
        if (this.memberInfo.mobile && this.payCode !== this.memberInfo.mobile) {
          this.$message.error("请使用查询到的会员进行支付");
          return false;
        } else if (this.memberInfo.mobile) {
          // 已经登录会员
          if (this.memberPassword) {
            this.memberPay({
              memberId: this.member.id,
              payAccountPwd: this.memberPassword
            });
          } else {
            return this.checkOrderOneMemberInfo();
          }
        } else {
          // 没登录会员走弹窗输入支付密码
          this.isLoginMember = false;
          return true;
        }
      } else if (this.memberInfo.mobile) {
        // 已经登录会员
        return this.checkOrderOneMemberInfo();
      } else {
        // 不是会员码
        return true;
      }
    },
    // 检查会员信息
    checkOrderOneMemberInfo() {
      orderOneMemberInfo({
        evidence: this.memberInfo.mobile
      })
        .then(res => {
          this.member = res;
          this.showMember = true;
          this.isLoginMember = true;
          return false;
        })
        .catch(() => {
          return false;
        });
    },
    // 校验会员登录密码
    async checkMemberPass() {
      // 登录会员时扫会员码校验会员支付密码
      if (this.isLoginMember) {
        commonMember({
          cartId: this.cartId,
          memberPaymentPassword: this.memberPassword
        }).then(() => {
          this.showMember = false;
          this.createOrder();
        });
      } else {
        let isOk = await this.checkMemberPassword();
        if (!isOk) {
          this.memberPassword = "";
          return;
        }
        this.showMember = false;

        this.getMemberProperty();
        // this.memberPay({
        //   memberId: this.member.id,
        //   payAccountPwd: this.memberPassword
        // });
      }
    },
    // 下单
    async order() {
      if (!this.cartId) {
        this.$message.error("请选购产品");
        return false;
      } else if (this.isModal || this.validate()) {
        // 有订单号就返回true
        if (this.orderId) {
          return true;
        } else {
          // 没订单id就下单
          return createQuickOrder({ shoppingCartId: this.cartId })
            .then(res => {
              this.orderId = res.orderId;
              this.ownerFormatTypes = res.ownerFormatTypes || [];
              this.settlementPrice = res.settlementPrice; // 订单金额
              // 线上支付并且是0元订单就直接支付成功
              if (res.settlementPrice === 0) {
                this.success();
                return false;
              } else {
                return true;
              }
            })
            .catch(() => {
              return false;
            });
        }
      }
    },

    // 支付，直接付款调用该方法
    async pay() {
      if (!this.orderId) {
        // 是否下单成功
        const ok = await this.order();
        if (!ok) {
          this.payValue = "payOnline";
          return false;
        }
      }
      this.memberPassword = "";
      this.showPayModal = true;
    },

    // 支付方式切换
    async payChange() {
      // 选择挂账没有登录会员则需要先登录会员才能挂账
      if (this.payValue === "payAfter" && !this.$store.state.common.member.id) {
        this.$message.warning("请先登录会员后挂账");
        this.$nextTick(() => {
          // 选回识别凭证
          this.payValue = "payOnline";
        });
        return;
      }
      // 如果有优惠
      if (this.preferential) {
        if (this.memberPassword) {
          this.payOrder();
        } else if (this.showMemberBalance) {
          // 是否登录会员
          const pwdOk = await this.checkMem();
          if (pwdOk) {
            this.createOrder();
          }
        }
      } else {
        this.createOrder();
      }
    },
    // 选择支付方式后下单
    async createOrder() {
      if (!this.orderId) {
        // 是否下单成功
        const ok = await this.order();
        if (!ok) {
          this.payValue = "payOnline";
          return false;
        }
      }
      // 线下支付
      if (this.payValue !== "payOnline") {
        this.memberPassword = "";
        this.showPayModal = true;
        // 线下非挂账
        if (this.payValue !== "payAfter") {
          const temp = this.payOffline.find(
            temp => temp.payWayId === this.payValue
          );
          this.payWayName = temp.payWayName;
        }
      }
      // 线上支付
      else {
        this.payOrder();
      }
    },
    // 确认支付
    surePay() {
      // 0元订单直接支付成功
      if (this.settlementPrice === 0) {
        this.success();
        return;
      }
      // 挂账
      if (this.payValue === "payAfter") {
        payOncreditOrder({
          orderId: this.orderId
        })
          .then(() => {
            this.success();
          })
          .catch(() => {});
      }
      // 线下支付
      else if (this.payValue !== "payOnline") {
        payOfflineOrder({
          orderId: this.orderId,
          payWayCode: this.payValue,
          payWayName: this.payWayName
        })
          .then(() => {
            this.success();
          })
          .catch(() => {});
      } else if (this.payValue === "payOnline") {
        if (!this.payCode) {
          this.$message.error("请输入/扫描凭证");
          return;
        }
        // 会员码支付
        if (/\d{12}D\d{4}/.test(this.payCode) && !this.showMemberBalance) {
          this.$message.error("不支持会员余额支付！");
        } else if (
          /(^1[3456789]\d{9}$)|(^09\d{8})$/.test(this.payCode) &&
          !this.showMemberBalance
        ) {
          this.$message.error("不支持会员余额支付！");
        }
      }
    },
    // 扫码回车
    async scanEnter() {
      if (!this.payCode) {
        this.$message.error("请输入/扫描凭证");
        return;
      }
      this.memberPassword = ""; // 初始化支付密码  防止切换会员了 不校验支付密码直接弹窗会员资产
      // 是否有优惠
      if (this.preferential && this.showMemberBalance) {
        const checkMem = await this.checkMem();
        if (!checkMem) {
          return;
        }
      }
      this.payOrder();
    },
    // 下单支付（识别凭证）
    async payOrder() {
      if (!this.orderId) {
        // 是否下单成功
        const ok = await this.order();
        if (!ok) return;
      }
      if (!this.payCode) {
        return;
      }
      // 会员码支付
      if (/\d{12}D\d{4}/.test(this.payCode)) {
        if (!this.showMemberBalance || !this.payMember) {
          this.$message.error("不支持的凭证！");
          return;
        }
        // 动态会员码就直接弹窗会员资产
        const memberInfoOk = await this.getOrderOneMemberInfo();
        if (memberInfoOk) {
          this.showMember = false;
          this.getMemberProperty();
        }
        // this.memberPay({ memberCode: this.payCode });
      } else if (/(^1[3456789]\d{9}$)|(^09\d{8})$/.test(this.payCode)) {
        if (!this.showMemberBalance || !this.payMember) {
          this.$message.error("不支持的凭证！");
          return;
        }
        // 有会员密码就直接调支付
        if (this.memberPassword) {
          //不直接调支付，先查询会员资产
          this.showMember = false;

          this.getMemberProperty();
        } else {
          // 没有会员支付密码就弹窗输入
          const memberInfoOk = await this.getOrderOneMemberInfo();
          if (memberInfoOk) {
            this.showMember = true;
          }
        }
      }
      // 线上支付
      else {
        this.onlinePay();
      }
    },
    // 获取会员信息
    async getOrderOneMemberInfo() {
      let ret = false;
      await orderOneMemberInfo({
        evidence: this.payCode
      })
        .then(res => {
          this.member = res;
          ret = true;
        })
        .catch(() => {});
      return ret;
    },
    // 会员抵扣支付
    memberPay(data) {
      const obj = {
        orderId: this.orderId,
        payWayCode: "MemberBalance",
        payWayName: this.payWayMemberName
      };
      const item = Object.assign(obj, data);
      payDeductionOrder(item)
        .then(() => {
          this.success();
        })
        .catch(() => {});
    },
    //根据会员信息、购物车信息获取会员资产信息
    async getMemberProperty() {
      const cartArr = await this.getCartInfo(this.cartId); // 获取购物车产品id
      memberPaymentInformation({
        memberId: this.member.id,
        cartId: this.cartId
      }).then(resp => {
        let nameMap = new Map();
        let countMap = new Map();
        resp?.memberProductInformationVO?.memberAvailableProductVOList?.map(
          item => {
            if (!nameMap.has(item.productId)) {
              nameMap.set(item.productId, item.productName);
            }

            if (!countMap.has(item.productId)) {
              countMap.set(item.productId, 0);
            }
            countMap.set(item.productId, countMap.get(item.productId) + 1);
          }
        );

        let productArr = Array.from(countMap, ([productId, count]) => {
          return {
            productId,
            count,
            productName: nameMap.get(productId) || ""
          };
        });

        productArr = productArr || [];

        if (resp.memberProductInformationVO) {
          resp.memberProductInformationVO.productAvailList = productArr;
        }
        // 默认勾选可兑换产品
        if (resp.memberProductInformationVO.whetherUsable) {
          let productAuxiliaryIdList = [];
          if (resp.memberProductInformationVO.memberAvailableProductVOList) {
            cartArr.forEach(cartItem => {
              resp.memberProductInformationVO.memberAvailableProductVOList.find(
                val => {
                  // 产品id相等并且购物车数组的数量大于0就push到勾选中，购物车数组数量减一
                  if (
                    cartItem.productId === val.productId &&
                    cartItem.buyNum > 0
                  ) {
                    // 没勾选的加入勾选中
                    if (!val.used) {
                      val.used = true; // 这条已经勾选，不能再使用
                      productAuxiliaryIdList.push(val.auxiliaryId);
                      cartItem.buyNum--;
                    }
                  }
                }
              );
            });
          }
          // 没有产品时
          if (productAuxiliaryIdList.length === 0) {
            resp.memberProductInformationVO.whetherUsable = false;
          }
          this.productAuxiliaryIdList = productAuxiliaryIdList; // 选中的产品
        }
        this.memberProperty = resp;
        this.checkConversion(resp);
      });
    },
    // 是否直接调用兑换支付
    checkConversion(resp) {
      // 只有兑奖券可用时
      if (
        resp.memberAssetCoinCertificateVO.whetherUsable &&
        !resp.memberConvertibleCurrencyVO.whetherUsable &&
        !resp.memberProductInformationVO.whetherUsable
      ) {
        // 只有兑奖券可用时,直接调兑奖券支付
        this.doPayCouponOrder();
      } else if (
        !resp.memberAssetCoinCertificateVO.whetherUsable &&
        resp.memberConvertibleCurrencyVO.whetherUsable &&
        !resp.memberProductInformationVO.whetherUsable
      ) {
        // 只有兑换币可用时,直接调兑换币支付
        this.doMemberPay();
      } else if (
        !resp.memberAssetCoinCertificateVO.whetherUsable &&
        !resp.memberConvertibleCurrencyVO.whetherUsable &&
        resp.memberProductInformationVO.whetherUsable &&
        resp.memberProductInformationVO.memberAvailableProductVOList.length ===
          1
      ) {
        // 只有产品兑换且只有一个产品时就直接调产品核销
        this.sendWriteOffProduct(this.productAuxiliaryIdList);
      } else {
        this.showMemberProperty = true;
      }
    },
    // 线上支付
    onlinePay() {
      if (this.loading) return;
      this.loading = true;
      const data = {
        orderNo: this.orderId,
        authCode: this.payCode
      };
      tradePaymentInfoMicropayV2(data, { loading: false })
        .then(res => {
          // 等待支付
          if (res === "PAYING" || res === "NOT_PAY") {
            this.getStatusLoop();
            this.showWait = true;
          }
          // 支付失败
          else if (res === "PAY_FAIL") {
            // this.paySpeak("支付失败，请重新支付");
            this.$message.error("支付失败，请重新支付");
            this.loading = false;
          }
          // 支付成功 除了以上状态都可认为支付成功 res === "PAID"
          else {
            this.success();
          }
          // // 支付超时or订单已经失效（30分钟未支付失效）
          // // 这种场景，已经走接口的catch了，不会在这边执行
          // if (res == "CANCEL") {
          //   console.log("订单已经失效，请重新下单+++++");
          //   return;
          // }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 轮询支付状态
    getStatusLoop() {
      if (this.loopTimer) {
        clearTimeout(this.loopTimer);
      }
      this.loopTimer = setTimeout(() => {
        tradePaymentInfoStatus({ orderNo: this.orderId, loading: false })
          .then(res => {
            if (res === "PAYING" || res === "NOT_PAY") {
              this.getStatusLoop();
            }
            // 支付失败
            else if (res === "PAY_FAIL") {
              // this.paySpeak("支付失败，请重新支付");
              this.$message.error("支付失败，请重新支付");
              this.showWait = false;
              this.loading = false;
            }
            // 支付成功 除了以上状态都可认为支付成功 res === "PAID"
            else {
              this.success();
            }
          })
          .catch(() => {
            this.showWait = false;
            this.loading = false;
          });
      }, 2000);
    },
    // 取消
    clear() {
      clearTimeout(this.loopTimer);
      this.loopTimer = null;
      this.loading = false;
      this.showMember = false;
      this.showPayModal = false;
      this.showWait = false;
      this.refundPrice = 0;
      this.ownerFormatTypes = [];
      this.orderId = "";
      this.payCode = "";
      this.payValue = "payOnline";
      this.memberPassword = "";
      this.showMemberProperty = false;
    },
    // 支付成功
    success() {
      this.$message.success("支付成功");
      // this.$success({
      //   title: "提示",
      //   content: "支付成功"
      // });
      // 调打印和语音播报
      // this.payOk(this.ownerFormatTypes, this.orderId);
      this.payOk(this.orderId);
      this.clear();
      this.$emit("success", true);
    },
    //关闭会员资产弹窗
    onClickCancelMemberProperty() {
      this.showMemberProperty = false;
    },
    //点击核销产品
    onClickWriteOffProduct() {
      if (!this.productAuxiliaryIdList.length) {
        this.$message.warn("请选择要核销的产品");
        return;
      }
      if (this.buyNum != this.productAuxiliaryIdList.length) {
        this.$message.warn("核销产品数和购物车产品数不一致");
        return;
      }
      this.$confirm({
        title: "确定要执行核销操作吗?",
        okText: "确定",
        onOk: () => {
          this.sendWriteOffProduct(this.productAuxiliaryIdList);
        }
      });
    },
    sendWriteOffProduct(codeList) {
      productWriteOffStrictBatch({
        codeList: codeList
      }).then(res => {
        if (res.code === 500) {
          this.$message.warning(res.otherMsg ? res.otherMsg : "核销失败！");
        } else {
          this.$message.success(res.otherMsg ? res.otherMsg : "核销成功！");

          this.$emit("success", true);
          this.clear();
        }
      });
    },
    // 兑换券支付
    coinCertificatePay() {
      this.$confirm({
        title: "确定要执行兑换操作吗?",
        okText: "确定",
        onOk: () => {
          this.doPayCouponOrder();
        }
      });
    },
    // 兑换券支付接口
    doPayCouponOrder() {
      payCouponOrder({
        orderId: this.orderId,
        memberId: this.member.id
      })
        .then(() => {
          this.success();
        })
        .catch(() => {});
    },
    //兑换币支付
    onClickCurrencyPay() {
      this.$confirm({
        title: "确定要执行兑换操作吗?",
        okText: "确定",
        onOk: () => {
          this.doMemberPay();
        }
      });
    },
    // 兑换币支付接口
    doMemberPay() {
      if (/\d{12}D\d{4}/.test(this.payCode)) {
        // 动态二维码就直接传动态码
        this.memberPay({ memberCode: this.payCode });
      } else {
        // 手机号要校验会员密码
        this.memberPay({
          memberId: this.member.id,
          payAccountPwd: this.memberPassword
        });
      }
    },
    // 自定义规则-选择
    changeChecked(e) {
      let isCheck = e.target.checked;
      let value = e.target.value;
      let list = this.productAuxiliaryIdList;
      // console.log(list);
      if (isCheck) {
        if (!list.includes(value)) {
          list.push(value);
        }
      } else {
        list = list.filter(item => item !== value);
      }
      this.productAuxiliaryIdList = list;
    },
    //获取购物车详情
    async getCartInfo(cartId) {
      let arr = [];
      await cartCommonDetail({
        cartId: cartId || this.cartId
      })
        .then(res => {
          let productIdArr = []; // 获取购物车里产品详情
          let buyNum = 0;
          if (res.detailProductList.length) {
            res.detailProductList.forEach(item => {
              let itemArr = {
                buyNum: item.productFlagPO.buyNum,
                productId: item.productId
              };
              buyNum += item.productFlagPO.buyNum;
              productIdArr.push(itemArr);
            });
          }
          this.cartInfo = productIdArr;
          this.buyNum = buyNum;
          arr = productIdArr;
        })
        .catch(() => {});
      return arr;
    },
    //单独校检用户密码是否正确
    async checkMemberPassword() {
      let isPass = false;
      await checkMemberPasswordInfo({
        memberId: this.member.id,
        password: this.memberPassword
      })
        .then(resp => {
          if (!resp) {
            isPass = true;
          }
        })
        .catch(() => {
          isPass = false;
        });
      return isPass;
    },

    // 扫码
    readIdCard() {
      HardwareService.readIdCard(res => {
        const { id } = JSON.parse(res);
        this.payCode = id;
        this.scanEnter();
      });
    },
    clearReadIdCard() {
      HardwareService.clearReadIdCard();
    }
  }
};
</script>

<style scoped lang="less">
.pay-way-radio {
  margin-top: 8px;
}

/*确认订单弹窗*/
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  background: rgba(12, 12, 12, 0.42);
  .center {
    width: 500px;
    border-radius: 17px;
    background: #fff;
    z-index: 3;
    .pay-way-s {
      max-height: 65vh;
      overflow-y: auto;
    }
    .modal-radio {
      display: block;
      height: 40px;
      margin-bottom: 12px;
      line-height: 40px;
    }
  }
}

.member-property-modal {
  .center {
    padding: 24px 0;
    .model-center-main {
      max-height: 400px;
      overflow-y: auto;
    }
  }
  hr {
    border-top: 1px solid #f1f1f1;
    border-bottom: none;
    height: 0;
  }
  .highlight {
    color: #3566d9;
  }
  .title {
    font-weight: bold;
  }

  .modal-content-list-item {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 16px;
  }

  .checkbox-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    .checkbox-list-item {
      margin-bottom: 10px;
    }
  }
}
</style>

<style lang="less">
.member-property-modal {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      margin-left: 0 !important;
    }
  }
}
.loading-box {
  padding: 40px 0px;
  .loading-icon {
    width: 64px;
    animation: an1 3s infinite;
  }
  @keyframes an1 {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(180deg);
    }
    50% {
      transform: rotateZ(180deg);
    }
    75% {
      transform: rotateZ(360deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
}
</style>
