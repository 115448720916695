<!--
取票弹窗
props: {
  // 显示取票弹窗
  visible: {
    type: Boolean,
    default: false
  }
},
-->
<template>
  <div>
    <!-- 产品列表弹窗 -->
    <a-modal
      width="1000px"
      :visible="visible"
      title="产品列表"
      @cancel="close"
      @ok="printOk"
      :mask-closable="false"
      :body-style="{
        maxHeight: '500px',
        overflowY: 'auto'
      }"
    >
      <a-input-search
        v-inputfocus
        allow-clear
        class="search-input"
        placeholder="请输入单号,票号,手机号,卡腕带号等凭证号"
        v-model="searchKey"
        @search="onSearch"
      >
        <a-button slot="enterButton" icon="search" type="primary" ghost>
          搜索
        </a-button>
      </a-input-search>
      <div class="mt-2x">
        <a-table
          :columns="columns"
          :data-source="productList"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }"
          :rowKey="record => record.id"
        >
          <template slot-scope="row" slot="sellingPrice">
            {{ row.sellingPrice | money }}
          </template>
          <template slot-scope="row" slot="totalAmount">
            {{ row.totalAmount | money }}
          </template>
          <template slot-scope="row" slot="action">
            <a-button @click="editPrint(row)" type="link"
              >设置打印规则</a-button
            >
          </template>
        </a-table>
      </div>
    </a-modal>
    <!-- 打印规则弹窗 -->
    <a-modal
      width="1200px"
      v-model="showCustom"
      title="修改打印规则"
      :mask-closable="false"
      @ok="handleOk"
    >
      <a-form-model
        ref="ruleForm"
        :model="printForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" prop="name">
          <a-radio-group
            v-model="printForm.cartPrintType"
            @change="printTypeChange"
          >
            <a-radio
              class="radio-style"
              :value="item.value"
              v-for="item in types"
              :key="item.value"
            >
              {{ item.name }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="本次打印票数"
          v-if="printForm.cartPrintType === 3"
        >
          <a-input-number
            v-inputfocus
            class="print-num"
            :min="1"
            :max="tempRow.buyNum"
            :step="1"
            v-model="printForm.printNum"
            placeholder="请输入"
            allow-clear
            addon-after="张"
            @blur="changeNum"
          ></a-input-number>
          <span class="ml-1x">张</span>
        </a-form-model-item>
        <a-form-model-item
          label="每张票包含产品数"
          v-if="printForm.cartPrintType === 3"
        >
          <div class="flex-start flex-wrap">
            <div
              v-for="(item, index) in printForm.times"
              :key="index"
              class="mr-2x mb-1x"
            >
              <div>
                <span class="mr-1x">{{ `第${index + 1}张` }}</span>
                <a-input-number
                  v-inputfocus
                  :min="0"
                  :max="item.maxTotalNum"
                  :step="1"
                  class="print-time"
                  v-model="item.num"
                  placeholder="请输入"
                  addon-after="次"
                  allow-clear
                  @blur="blurWriteOff"
                  @focus="focusNum(item, index)"
                ></a-input-number>
              </div>
              <p class="blue pointer text-c" @click="showDetail(index)">
                查看明细
              </p>
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
      <!--当前共100个成人票，本次打印2张票，包含60个成人票，剩余40个成人票。-->
      <p v-if="printForm.cartPrintType === 3">
        提示：{{ tempRow.productName }}共购买<span class="red bold mh-1x">{{
          tempRow.buyNum
        }}</span
        >个，可出票<span class="red bold mh-1x">{{ tempRow.buyNum || 0 }}</span
        >个，本次出票<span class="red bold mh-1x">{{
          printForm.printNum || 0
        }}</span
        >个，包含产品数<span class="red bold mh-1x">{{ cludeNum }}</span
        >个
      </p>
    </a-modal>
    <!-- 查看明细 -->
    <a-modal
      width="1200px"
      v-model="isShowDetail"
      title="查看明细"
      :mask-closable="false"
      @cancel="cancelDetail"
      @ok="checkedOk"
    >
      <div>
        票号列表：<a-checkbox value="A" :checked="false">可用</a-checkbox
        ><a-checkbox value="A" :checked="true">已选</a-checkbox
        ><a-checkbox value="A" disabled>已用</a-checkbox>
      </div>
      <a-checkbox-group v-model="checkboxGroup" class="mt-2x">
        <a-checkbox
          v-for="item in ticketsList"
          :key="item.id"
          :value="item.id"
          :disabled="item.checked !== '' && item.checked !== checkIndex"
          @change="changeChecked(item)"
          >{{ item.productName }}</a-checkbox
        >
      </a-checkbox-group>
    </a-modal>
  </div>
</template>

<script>
import {
  orderTaketicketslist,
  orderSubordertaketicket
} from "../../../api/search";
import { findDefindPrintInfo, saveDefindPrintInfo } from "../../../api/order";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
export default {
  name: "TakeTicket",
  props: {
    // 显示取票弹窗
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        {
          title: "产品名称",
          key: "productName",
          dataIndex: "productName"
        },
        {
          title: "单价",
          key: "sellingPrice",
          scopedSlots: { customRender: "sellingPrice" }
        },
        {
          title: "数量",
          key: "buyNum",
          dataIndex: "buyNum"
        },
        {
          title: "小计",
          key: "totalAmount",
          scopedSlots: { customRender: "totalAmount" }
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      selectedRowKeys: [], // 多选选择的key
      productList: [], // 产品列表
      showCustom: false, // 是否显示弹窗
      printForm: {
        cartPrintType: 1, //打印规则：1后台打印规则，2窗口打印规则，3自定义规则
        printNum: 2, //本次打印数量
        times: [{ num: 1, maxTotalNum: 1 }] //每张票支持核销次数，数字数组
      },
      rules: {},
      types: [
        {
          name: "一个产品一票",
          value: 1
        },
        {
          name: "一种产品一票",
          value: 2
        },
        {
          name: "自定义规则",
          value: 3
        }
      ],
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      cludeNum: 0, // 包含票数
      remainingNum: 0, // 剩余票数
      tempRow: {
        buyNum: 0
      },
      checkboxGroup: [], // 产品选择列表
      cacheData: [], //缓存选中产品
      ticketsList: [], // 产品列表
      isShowDetail: false, //显示明细
      usedNum: 0, // 产品已使用数量
      checkIndex: 0, // 选中
      searchKey: "" // 搜索的key
    };
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 保存打印规则
    handleOk() {
      let productDefindPrintDTOList = {};
      // 自定义规则数据
      if (this.printForm.cartPrintType === 3) {
        let defindPrintInfoList = [];
        this.printForm.times.forEach((val, key) => {
          let numList = [];
          this.ticketsList.forEach(item => {
            if (item.checked === key) {
              numList.push(item.id);
            }
          });
          defindPrintInfoList.push({ numList });
        });
        productDefindPrintDTOList = {
          subOrderId: this.tempRow.id,
          num: this.printForm.printNum,
          productName: this.tempRow.productName,
          defindPrintInfoList
        };
      }
      this.productList.forEach(item => {
        if (item.id === this.tempRow.id) {
          item.printType = this.printForm.cartPrintType;
          item.productDefindPrintDTOList = productDefindPrintDTOList;
        }
      });
      this.showCustom = false;
    },
    // 取票确认
    printOk() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择产品");
        return;
      }
      let arr = [];
      let productDefindPrintDTOList = [];
      this.productList.forEach(item => {
        if (this.selectedRowKeys.indexOf(item.id) !== -1) {
          arr.push({
            id: item.id,
            printWay: item.printType
          });
          if (item.printType === 3) {
            productDefindPrintDTOList.push(item.productDefindPrintDTOList);
          }
        }
      });
      // 有自定义规则先保存
      if (productDefindPrintDTOList.length > 0) {
        saveDefindPrintInfo({ productDefindPrintDTOList })
          .then(() => {
            this.takeTicket(arr);
          })
          .catch();
      } else {
        //取票
        this.takeTicket(arr);
      }
    },
    // 取票
    takeTicket(arr) {
      orderSubordertaketicket(arr)
        .then(res => {
          if (res.length > 0) {
            HardwareService.printTicket(JSON.stringify(res));
            this.$message.success("正在打印中");
            this.close();
          }
        })
        .catch({});
    },
    // 自定义规则-选择
    changeChecked(item) {
      if (this.checkboxGroup.indexOf(item.id) === -1) {
        item.checked = this.checkIndex;
      } else {
        item.checked = "";
      }
    },
    // 自定义规则-选中确认
    checkedOk() {
      if (
        this.printForm.times[this.checkIndex].num !== this.checkboxGroup.length
      ) {
        this.$message.error("选中票数不等于填写票数");
      } else {
        this.isShowDetail = false;
      }
    },
    // 自定义规则-查看详情取消
    cancelDetail() {
      for (let j = 0; j < this.ticketsList.length; j++) {
        // 选中的没缓存在前面分配的就清空
        if (
          this.ticketsList[j].checked === this.checkIndex &&
          this.cacheData.indexOf(this.ticketsList[j].id) === -1
        ) {
          this.ticketsList[j].checked = "";
        } else if (
          this.ticketsList[j].checked === "" &&
          this.cacheData.indexOf(this.ticketsList[j].id) !== -1
        ) {
          // 取消掉选中的，在缓存数据中的就重新选中
          this.ticketsList[j].checked = this.checkIndex;
        }
      }
    },
    // 自定义规则-查看明细
    showDetail(index) {
      this.checkIndex = index;
      this.isShowDetail = true;
      let checkboxGroup = [];
      // 初始化选中值
      for (let j = 0; j < this.ticketsList.length; j++) {
        if (this.ticketsList[j].checked === index) {
          checkboxGroup.push(this.ticketsList[j].id);
        }
      }
      this.cacheData = checkboxGroup; // 缓存一份选中的值，在改变时选中产品取消时还原数据
      this.checkboxGroup = checkboxGroup;
    },
    // 获取焦点 计算可输入最大产品数
    focusNum(item, index) {
      let num = 0;
      this.printForm.times.forEach((val, key) => {
        if (index !== key) {
          num += val.num;
        }
      });
      // 本张票最大输数 = 购买数量 - 其它框输入的数量 - 产品已使用数量
      item.maxTotalNum = this.tempRow.buyNum - num - this.usedNum;
    },
    close() {
      this.$emit("update:visible", false);
    },
    onSearch(val) {
      if (val) {
        orderTaketicketslist({
          keyword: val
        })
          .then(res => {
            // 根据票号查直接打印
            if (res.printInfo.length > 0) {
              HardwareService.printTicket(JSON.stringify(res.printInfo));
              this.$message.success("正在打印中");
              this.close();
            } else {
              // 根据订单查
              res.productInfo.forEach(item => {
                item.printType = 1;
              });
              this.productList = res.productInfo;
            }
          })
          .catch({});
      } else {
        this.productList = [];
      }
    },
    // 选择打印方式
    printTypeChange() {
      if (this.printForm.cartPrintType === 3) {
        this.getFindDefindPrintInfo();
      }
    },
    // 查询产品的自定义打印规则
    getFindDefindPrintInfo() {
      findDefindPrintInfo({
        subOrderId: this.tempRow.id
      })
        .then(res => {
          let usedNum = 0;
          for (let i = 0; i < res.length; i++) {
            // 计算已使用的产品
            if (res[i].status === 6) {
              usedNum += 1;
            }
          }
          this.usedNum = usedNum;
          this.ticketsList = res;
        })
        .catch({});
    },
    // 匹配产品
    checkTicket() {
      // 初始化选中值
      for (let j = 0; j < this.ticketsList.length; j++) {
        this.ticketsList[j].checked = ""; // 选中
      }
      for (let i = 0; i < this.printForm.times.length; i++) {
        let num = this.printForm.times[i].num;
        for (let j = 0; j < this.ticketsList.length; j++) {
          if (this.ticketsList[j].checked === "" && num !== 0) {
            this.ticketsList[j].checked = i;
            num--;
          }
        }
      }
    },
    // 设置打印规则
    editPrint(row) {
      if (this.tempRow.id !== row.id) {
        this.printForm.cartPrintType = row.printType;
      }
      this.tempRow = row;
      this.printForm.printNum =
        row.printType === 3 ? row.productDefindPrintDTOList.num : "";
      this.printForm.writeOffNum = row.buyNum || [1];
      this.showCustom = true;
      this.changeNum();
    },
    //计算核销次数之和及剩余核销次数
    calculateNum() {
      let totalNum = 0;
      for (let i = 0; i < this.printForm.times.length; i++) {
        totalNum += this.printForm.times[i].num;
      }
      this.cludeNum = totalNum;
      this.remainingNum = this.tempRow.buyNum - totalNum;
      this.checkTicket();
    },
    //本次打印票数改变
    changeNum() {
      if (this.printForm.printNum > this.tempRow.buyNum) {
        this.$message.error("打印的票数不能大于产品数量");
      } else {
        let defindPrintInfoList = [];
        if (this.tempRow.productDefindPrintDTOList) {
          defindPrintInfoList = this.tempRow.productDefindPrintDTOList
            .defindPrintInfoList;
        }
        this.printForm.times = [];
        for (let i = 0; i < this.printForm.printNum; i++) {
          let num =
            defindPrintInfoList.length > 0
              ? defindPrintInfoList[i]
                ? defindPrintInfoList[i].numList.length
                : 1
              : 1;
          this.printForm.times.push({
            num,
            maxTotalNum: num
          });
        }
      }
      this.calculateNum();
    },
    //产品数量失去焦点
    blurWriteOff() {
      this.calculateNum();
    }
  }
};
</script>

<style lang="less" scoped>
.radio-style {
  display: block;
  height: 30px;
  line-height: 30px;
}
.search-input {
  width: 389px;
}
</style>
