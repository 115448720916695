<!-- 取单 -->
<template>
  <a-modal
    width="1000px"
    :visible="visible"
    title="取单"
    :footer="null"
    @cancel="close"
    :mask-closable="false"
    :body-style="{
      maxHeight: '500px',
      overflowY: 'auto'
    }"
  >
    <template v-if="cartList.length">
      <section
        v-for="(item, index) in cartList"
        :key="item.id"
        class="item shadow"
      >
        <header class="flex-between header bd-b">
          <p>
            订单总价：<span class="price">{{
              item.totalPrice || 0 | money
            }}</span>
          </p>
          <p>
            挂单时间：{{
              item.cartFlagPO.pauseCartTime | date("YYYY-MM-DD hh:mm:ss")
            }}
          </p>
        </header>
        <section class="p-2x">
          <a-row :gutter="[16, 16]">
            <a-col
              :xxl="6"
              :xl="8"
              :lg="12"
              v-for="(productItem, idx) in item.productList"
              :key="idx"
            >
              <div class="card flex-between flex-col shadow">
                <p class="text-1 font-xl">{{ productItem.productName }}</p>
                <p>数量：{{ productItem.productFlagPO.buyNum }}</p>
                <p>单价：{{ productItem.sellingAmount | money }}</p>
              </div>
            </a-col>
          </a-row>
        </section>
        <footer class="flex-end footer">
          <a-button
            type="primary"
            shape="round"
            ghost
            class="mr-2x"
            @click="deleteOrder(item.id, index)"
            >删除</a-button
          >
          <a-button type="primary" shape="round" @click="takeOrder(item.id)"
            >取单</a-button
          >
        </footer>
      </section>
    </template>

    <a-empty v-else :image="simpleImage" />
  </a-modal>
</template>

<script>
import { Empty } from "ant-design-vue";
import {
  commonCartList,
  commonDeleteCart,
  commonTakeOut
} from "../../../api/cart";
import { mapGetters } from "vuex";

export default {
  name: "TakeCart",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    cardId: {
      type: [Number, String],
      default: ""
    }
  },
  computed: {
    ...mapGetters("common", ["memberInfo"]),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    }
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      cartId: this.$route.query.cartId, // 购物车id
      cartList: [] // 购物车列表
    };
  },

  created() {},
  methods: {
    getList() {
      //  16 :"会员" 6 :"票务" 7 :"游乐" 8 :"剧院" 9 :"酒店" 10 :"餐饮" 11 :"零售" 12 :"租赁" 13 :"组合"
      const val = {
        appHome: 6,
        appNewHome: 6,
        appPlayNew: 7,
        appCombinationNew: 13,
        appRetailNew: 11
      };
      commonCartList({
        id: this.cartId || "",
        pauseCartFormatType: val[this.currentTopMenuKey]
      })
        .then(res => {
          this.cartList = res;
          this.$emit("update:visible", true);
        })
        .catch(() => {});
    },
    // 取单
    takeOrder(id) {
      commonTakeOut({
        id
      })
        .then(res => {
          this.$emit("change", res);
          this.close();
        })
        .catch(() => {});
    },

    // 删除单
    deleteOrder(id, index) {
      commonDeleteCart({
        cartId: id
      })
        .then(() => {
          this.cartList.splice(index, 1);
          this.$message.success("删除成功");
        })
        .catch(() => {});
    },

    close() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom";
.item {
  margin-bottom: 16px;
  background: #fff;
  border-radius: 5px;
  &:first-child {
    border: none;
  }
  .header {
    padding: 12px 16px;
    font-size: 16px;
    background: @primary-light1;
  }
  .card {
    height: 130px;
    padding: 12px;
    border-radius: 5px;
  }
  .footer {
    padding: 0 16px 16px 16px;
  }
}
</style>
